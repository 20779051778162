import { BrowserRouter, Route, Routes } from "react-router-dom";

import './App.css';
import Signup from "./signup";
import logo from "./logo512.png"

function App() {
    const landingPageHtml = (<div className="App">
        <div><img className="Logo" src={logo} alt="Bondsynth AI Logo"></img></div>
        <p>Thank you for your interest!<br />
            Bondsynth AI is no longer accepting new users.</p>
    </div >);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={landingPageHtml} />
            </Routes >
        </BrowserRouter>
    );
}

export default App;
